<template>
  <b-row>
    <b-col cols="12">
      <div>

        <!-- Basic Info tab -->
        <validation-observer
          ref="accountRules"
          tag="form"
        >
          <b-card title="Add Expense Head">
            <b-row>
              <b-col md="6">
                <b-form-group
                  label="Expense Source"
                  label-for="expenseSource"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Expense Source"
                    rules="required"
                  >
                    <v-select
                      id="expenseSource"
                      v-model="expenseSource"
                      :options="['Team', 'Program 1', 'Program ']"
                      label="Expense Source"
                      push-tags
                      taggable
                      placeholder="Select or Add Expense Source"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Expense Head"
                  label-for="expenseHead"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Expense Head"
                    rules="required"
                  >
                    <b-form-input
                      id="expenseHead"
                      v-model="expenseHead"
                      placeholder="Expense Head"
                      type="text"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>
        </validation-observer>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BCard, BFormInput,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BFormGroup,
    BCard,
    vSelect,
    BFormInput,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {
      expenseSource: '',
      expenseHead: '',
    }
  },
  methods: {
    formSubmitted() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Form Submitted',
          icon: 'EditIcon',
          variant: 'success',
        },
      })
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate()
          .then(success => {
            if (success) {
              resolve(true)
            } else {
              reject()
            }
          })
      })
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate()
          .then(success => {
            if (success) {
              resolve(true)
            } else {
              reject()
            }
          })
      })
    },
    validationFormAddress() {
      return new Promise((resolve, reject) => {
        this.$refs.addressRules.validate()
          .then(success => {
            if (success) {
              resolve(true)
            } else {
              reject()
            }
          })
      })
    },
    validationFormSocial() {
      return new Promise((resolve, reject) => {
        this.$refs.socialRules.validate()
          .then(success => {
            if (success) {
              resolve(true)
            } else {
              reject()
            }
          })
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
